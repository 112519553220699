import moment from "moment";
import Vue from 'vue';
import MasterService from '@/services/MasterService';
import DistributorService from '@/services/DistributorService';
import { addAllKeyWordsIntoArrays, addAllKeyWordsIntoArrays_2 } from '@/helper/collection';

import { router } from '@/router';

const state = {
    CinemasList: [],
    DistributorsList: [],
    DistributorsMasterMovieList: [],
    PosUsersList: [],
    PaymentTypesList: [],
    OfferVoucherTypesList: [],
    OrgImage: null,
    noOfScreens: 1,
    currentCinemaIndex: 0,
    BacktoSeatLayout: false
}

const mutations = {
    SET_POS_USERS_LIST: (state, payload) => {
        state.PosUsersList = addAllKeyWordsIntoArrays(payload);
    },
    SET_PAYMENT_TYPES_LIST: (state, payload) => {
        state.PaymentTypesList = addAllKeyWordsIntoArrays(payload, 'pm_payment_mode');
    },
    SET_OFFERS_VOUCHER_TYPES_LIST: (state, payload) => {
        state.OfferVoucherTypesList = payload;
    },
    SET_CINEMAS_LIST: (state, payload) => {
        state.CinemasList = payload;
    },
    SET_DISTRIBUTORS_LIST: (state, payload) => {
        state.DistributorsList = payload;
    },
    SET_DISTRIBUTORS_MASTER_MOVIES_LIST: (state, payload) => {
        state.DistributorsMasterMovieList = payload;
    },
    //setup mutations
    SET_ORG_IMAGE: (state, payload) => {
        state.OrgImage = payload;
    },
    SET_NO_SCREENS: (state, payload) => {
        state.noOfScreens = payload;
    },
    SET_CURRENT_CINEMA_INDEX: (state, payload) => {
        state.currentCinemaIndex = state.currentCinemaIndex + 1;
    },
    SET_BoxOfficeFnb_Back_SeatLayout: (state, payload) => {
        state.BacktoSeatLayout = true
    }
}

const actions = {
    boxOfficefnb_back_seatlayout: ({ commit }, payload) => {
        commit('SET_BoxOfficeFnb_Back_SeatLayout', payload)
    },
    fetchCinemasList: ({ dispatch, commit, state }, payload) => {
        let body = {
            tableName: "ms_cinemas",
            cine_is_active: "Y",
        };
        MasterService.GetActiveList(body)
            .then(response => {
                const { data } = response;
                if (data && data.status) {
                    data.Records.length > 0 ? commit('SET_CINEMAS_LIST', data.Records || []) : '';
                }
                else Vue.toasted.show(data.message || 'Opps! Something went wrong');
            })
            .catch(error => {
                Vue.toasted.show("Catch Error on MasterStore fetchCinemasList", error);
            });
    },
    fetchDistributorsList: ({ dispatch, commit, state }, payload) => {
        let body = {
            tableName: "ms_distributors",
            d_is_active: "Y",
        };
        // screen_id: screen.screen_id
        MasterService.GetActiveList(body)
            .then(response => {
                const { data } = response;
                if (data && data.status) {
                    data.Records.length > 0 ? commit('SET_DISTRIBUTORS_LIST', data.Records || []) : '';
                }
                else Vue.toasted.show(data.message || 'Opps! Something went wrong');
            })
            .catch(error => Vue.toasted.show("Catch Error on MasterStore fetchDistributorsList", error));
    },
    fetchDistributorsActiveMasterMovie: ({ dispatch, commit, state }, payload) => {
        DistributorService.GetDistributorActiveMasterMovie()
            .then((response) => {
                const { data } = response;
                if (data && data.status) {
                    if (data.Records.length > 0) commit('SET_DISTRIBUTORS_MASTER_MOVIES_LIST', data.Records || []);
                }
                else Vue.toasted.show(data.message || 'Opps! Something went wrong');
            }).catch((error) => Vue.toasted.show("Catch Error on MasterStore fetchDistributorsActiveMasterMovie", error));
    },
    fetchPosUsersList: ({ dispatch, commit, state }, payload) => {
        let body = {
            tableName: "users",
            is_active: "Y",
            role_id: 4
        };
        MasterService.GetActiveList(body)
            .then(response => {
                const { data } = response;
                if (data && data.status) {
                    data.Records.length > 0 ? commit('SET_POS_USERS_LIST', data.Records || []) : '';
                }
                else Vue.toasted.show(data.message || 'Opps! Something went wrong');
            })
            .catch(error => {
                Vue.toasted.show("Catch Error on MasterStore fetchPosUsersList", error);
            });
    },
    fetchPaymentTypesList: ({ dispatch, commit, state }, payload) => {
        let body = {
            tableName: "ms_payment_modes",
            pm_is_active: "Y",
        };
        MasterService.GetActiveList(body)
            .then(response => {
                const { data } = response;
                if (data && data.status) {
                    data.Records.length > 0 ? commit('SET_PAYMENT_TYPES_LIST', data.Records || []) : '';
                }
                else Vue.toasted.show(data.message || 'Opps! Something went wrong');
            })
            .catch(error => {
                Vue.toasted.show("Catch Error on MasterStore fetchPaymentTypesList", error);
            });
    },
    //setup actions
    setOrgImage: ({ dispatch, commit, state }, payload) => {
        commit("SET_ORG_IMAGE", payload);
    },
    setNoOfScreens: ({ dispatch, commit, state }, payload) => {
        commit("SET_NO_SCREENS", payload);
    },
    setCurrentCinemaIndex: ({ dispatch, commit, state }, payload) => {
        commit("SET_CURRENT_CINEMA_INDEX", payload);
    },
    globalRemoveFile: ({ dispatch, commit, state }, payload) => {
        // /api/admin / removereportfile
        MasterService.GlobalRemoveFile(payload)
            .then(response => {
                const { data } = response;

                // console.log('data :', data);
            })
            .catch(error => {
                console.log('error :', error);
            });
    },
    fetchOfferVoucherTypesList: ({ dispatch, commit, state }, payload) => {
        // THIS IS FOR fUTURE 
        // let body = {
        //     tableName: "",
        // };
        // MasterService.GetActiveList(body)
        //     .then(response => {
        //         const { data } = response;
        //         if (data && data.status && data.Records.length > 0) commit('SET_OFFERS_VOUCHER_TYPES_LIST', data.Records || []);
        //         else Vue.toasted.show(data.message || 'Opps! Something went wrong');
        //     })
        //     .catch(error => {
        //         Vue.toasted.show("Catch Error on MasterStore fetchOfferVoucherTypesList", error);
        //     });
    },
};

const getters = {
    getbacktoseatlayout: state => state.BacktoSeatLayout,
    // THIS IS FOR FUTURE
    getOfferVoucherTypesList: state => state.OfferVoucherTypesList,

    getCinemasList: state => state.CinemasList,
    getDistributorsList: state => state.DistributorsList,
    getPosUsersList: state => state.PosUsersList,
    getPaymentTypesList: state => state.PaymentTypesList,
    getDistributorsMasterMovieList: state => state.DistributorsMasterMovieList,
    //setup store
    getOrgImage: state => state.OrgImage,
    noOfScreens: state => state.noOfScreens,
    currentCinemaIndex: state => state.currentCinemaIndex
}

export default {
    state,
    mutations,
    actions,
    getters
}
