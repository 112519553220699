export const addAllKeyWordsIntoArrays = (array, key) => {
    if (array.length > 0) {
        let Obj = { ...array[0] };
        Object.keys(Obj).map((x) => { Obj[x] = null; key ? Obj[key] = 'All' : '' });
        return [Obj].concat(array);
    } else return array;
};

export const addAllKeyWordsIntoArrays_2 = (array, key) => {
    console.log('array, key :', array, key);
    if (array.length > 0) {
        let Obj = { ...array[0] };
        Object.keys(Obj).map((x) => { Obj[x] = null; key ? Obj[key] = 'All' : '' });
        console.log('Obj :', Obj);
        return [Obj].concat(array);
    } else return array;
};

export const generateRandomCode = (max) => {
    return Array(max).fill('').reduce((accumulator) => accumulator += Math.floor(Math.random() * (max - 2) + 2), '');
};

export const removeDuplicates = (originalArray, prop) => {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
    return newArray;
}